import React from "react"
import { graphql, Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  FlexWrapper,
  FlagWrapper,
} from "../../components/contentwrappers"
import ActionButton from "../../components/actionbutton"

import Img from "gatsby-image"

export default function Enformer({ data }) {
  const { posts } = data.enformer

  return (
    <div>
      <BackgroundImage
        fluid={data.royalCourtsImage.childImageSharp.fluid}
        alt="The Royal Courts of Justice"
        style={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "-9",
        }}
      ></BackgroundImage>

      <Layout>
        <SEO title="E-nformer" />
        <PageWrapper>
          <HeadingWrapper>
            <h1>
              <strong>E-nformer</strong>
            </h1>
          </HeadingWrapper>

          <TextWrapper>
            <p>
              Here are our articles that we have published to the Law Socity of
              Northern Ireland's{" "}
              <a
                href="https://www.lawsoc-ni.org/enformer"
                target="_blank"
                rel="noopener noreferrer"
              >
                E-nformer
              </a>
              .
            </p>
          </TextWrapper>

          <FlexWrapper enformer>
            {posts.map(post => (
              <FlagWrapper key={post.id}>
                <h3>{post.frontmatter.title}</h3>

                <small>{post.frontmatter.date}</small>
                <Img
                  fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                />
                <p style={{ margin: "0" }}>{post.frontmatter.description}</p>

                <ActionButton toRef={post.fields.slug}>
                  Read <strong>More</strong>
                </ActionButton>
                <p></p>
              </FlagWrapper>
            ))}
          </FlexWrapper>
        </PageWrapper>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query MyEnformerQuery {
    enformer: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "enformer" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        excerpt
        id
      }
    }

    royalCourtsImage: file(
      relativePath: { eq: "royal-courts-of-justice.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 5031, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
